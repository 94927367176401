import React, { useEffect, useState } from 'react';
import {
  Button as MaterialButton,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'gatsby-theme-material-ui';
import { navigate } from 'gatsby';
import * as API from '../../services/api';

const adminUserFields = [
  {
    key: 'uuid',
    label: 'ID',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'createdAt',
    label: 'Created At',
  },
  {
    key: 'updatedAt',
    label: 'Updated At',
  },
];

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),

    '& .MuiTypography-root': {
      flexGrow: 1,
    },
  },
  label: {
    width: '25%',
    maxWidth: '25%',
  },
}));

export default function AdminUsersShow(props) {
  const { uuid } = props;
  const classes = useStyles();

  const [adminUser, setAdminUser] = useState(null);

  useEffect(() => {
    API.getAdminUser(uuid).then((newAdminUser) => {
      if (newAdminUser) {
        setAdminUser(newAdminUser);
      }
    });
  }, [uuid]);

  if (!adminUser) {
    return <CircularProgress />;
  }

  const deleteAdminUser = async () => {
    if (window.confirm('Are you sure?')) {
      await API.deleteAdminUser(uuid);
      navigate('/admin_users');
    }
  };

  return (
    <>
      <Paper className={classes.nav}>
        <Typography variant="h6">Admin User details</Typography>
        <Button color="primary" to={`/admin_users/${uuid}/edit`}>
          Edit
        </Button>
        <MaterialButton color="secondary" onClick={deleteAdminUser}>
          Delete
        </MaterialButton>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableBody>
            {adminUserFields.map((field) => (
              <TableRow key={field.key}>
                <TableCell className={classes.label}>{field.label}</TableCell>
                <TableCell>{adminUser[field.key]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
