import React from 'react';
import Layout from '../components/layout/Layout';
import AdminUsersShow from '../components/admin_users/Show';

export default (props) => {
  const { uuid } = props.params;

  return (
    <Layout page="admin_users">
      <AdminUsersShow uuid={uuid} />
    </Layout>
  );
};
